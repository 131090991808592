//
import React, {useContext, useEffect} from 'react'
import AppContext from "../../context/AppContext";
//
import Index from "./recipe/Index";
import View from "./recipe/View";
import Create from "./recipe/Create";
import Update from "./recipe/Update";
import NoPage from "../NoPage";

const Recipe = () => {

    const $app = useContext(AppContext);


    useEffect(() => {
        $app.content.setTitle(true, false)
        $app.content.setTitle(true, true)
    }, [$app.content])


    let $pages = []

    $pages.push($app.content.showPage(<Index/>, 'Recipe', 'Index'))
    $pages.push($app.content.showPage(<Create/>, 'Recipe', 'Create'))
    $pages.push($app.content.showPage(<Update/>, 'Recipe', 'Update'))
    $pages.push($app.content.showPage(<View/>, 'Recipe', 'View'))

    return ($pages.join('') === '' ? <NoPage/> : $pages);
}

export default Recipe;