//
import React, {useContext, useEffect} from 'react'
import AppContext from "../../context/AppContext";
//
import Index from "./ingredient/Index";
import Create from "./ingredient/Create";
import Update from "./ingredient/Update";
import View from "./ingredient/View";
import NoPage from "../NoPage";

const Ingredient = () => {

    const $app = useContext(AppContext);

    useEffect(() => {
        $app.content.setTitle(true, false)
        $app.content.setTitle(true, true)
    })

    let $pages = []

    $pages.push($app.content.showPage(<Index/>, 'Ingredient', 'index'))
    $pages.push($app.content.showPage(<Create/>, 'Ingredient', 'Create'))
    $pages.push($app.content.showPage(<Update/>, 'Ingredient', 'Update'))
    $pages.push($app.content.showPage(<View/>, 'Ingredient', 'View'))

    return ($pages.join('') === '' ? <NoPage/> : $pages);
}

export default Ingredient;