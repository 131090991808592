import React from 'react'
import AppContext from "../../../context/AppContext";
//
import {Typography} from "antd";

class Info extends React.Component {


    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {}
    }


    /**
     * toggle loading state
     */
    loadToggle = () => this.setState({loading: !this.state.loading})


    /**
     * @return {AppBase}
     */
    app = () => {
        return this.context;
    }

    componentDidMount() {
        let $app = this.app();
        $app.record.getRecord((r) => {
            $app.content.setTitle('Профиль')
            this.setState({res: r})
        }, false, '/user/profile')
    }

    render() {

        let $app = this.app();

        const $form = $app.HInput.new(this)

        let $top = <Typography.Text type={'warning'}>Только пароль доступен для редактирования.</Typography.Text>;

        let $left = (
            <>
                {$form.password('tmpPassword', {}, {
                    rules: [
                        {required: $app.route.action === 'create'},
                        {min: 6}
                    ]
                })}
                {$form.text('email', {
                    disabled: true
                }, {rules: [{type: 'email', required: true}]})}
            </>
        )

        let $right = (
            <>
                {$form.text('first_name', {disabled: true})}
                {$form.text('last_name', {disabled: true})}
            </>
        )

        let $items = $form.renderFieldsByPositions($top, $left, $right, '',
            $form.submitButton({
                loading: this.state.loading,
                disabled: (this.state.attributes && !this.state.attributes.tmpPassword),
            })
        )

        return $form.render($items, {
            onFinish: () => {
                this.loadToggle()
                $app.api.post('/user/profile', this.state.attributes, (res) => {
                    this.loadToggle()
                    $app.setState({user: res});
                    $form.setAttr('tmpPassword', '', true)
                }, {redirect: false})
            }
        })
    }
}

export default Info;