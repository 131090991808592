import React from 'react'
import AppContext from "../context/AppContext";
//
import {Progress} from 'antd';

class BaseLayoutLoader extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        let step = 33.3

        this.state = {
            p: 0,
            step: step,
            step2: false,
            step2V: 0.3,
            speed: 120,
            stopOn: 99.9,
            visible: true,
        }
    }

    t = function (fn, t) {
        let timerObj = setInterval(fn, t);

        this.stop = function (func) {
            if (timerObj) {
                clearInterval(timerObj);
                timerObj = null;
            }
            if (typeof func === 'function')
                func(this)
            return this;
        }

        // start timer using current settings (if it's not already running)
        this.start = function () {
            if (!timerObj) {
                this.stop();
                timerObj = setInterval(fn, t);
            }
            return this;
        }

        // start with new or original interval, stop current interval
        this.reset = function (newT = t) {
            t = newT;
            return this.stop().start();
        }
    }

    /**
     * @type {function: AppBase}
     * @return {AppBase}
     */
    app = () => {
        return this.context
    }

    r = () => {
        return this.state.visible ? <Progress strokeColor={{
            '0%': '#c10000',
            '100%': '#d00000',
        }} className="layout-loader" showInfo={false} percent={this.state.p}/> : ''
    }


    progress = () => {
        if (this.state.p >= 100) {
            this.state.t.stop()
            this.setState({visible: false})
            return;
        }

        if (this.state.p === this.state.stopOn)
            this.setState({step2: this.state.step2V})

        this.setState({p: this.state.p + (this.state.step2 ? this.state.step2 : this.state.step)})

    }

    s1 = () => {
        let t = this.state.t
        if (!t) {
            this.setState({
                t: new this.t(() => {
                    this.progress()
                }, this.state.speed)
            })
        }
        if (this.state.p >= 100) {
            this.setState({p: 0, step2: false, visible: true})
            t.stop()
        }

    }

    s2 = () => {
        if (this.state.step2)
            this.setState({step2: false})
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.app().content.isLoading())
            this.s1()
        if (!this.app().content.isLoading())
            this.s2()
        return (!this.app().content.isLoading() || this.app().content.isLoading())
    }

    render = () => {
        return this.r()
    }
}

export default BaseLayoutLoader;