//
import React from 'react'
import AppContext from "../../../context/AppContext";
//
import {Row, Col, Drawer, Tag, Button} from "antd";

class AddIngredientDrawer extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {};
    }


    loadingToggle = () => {
        this.setState({
            loading: !this.state.loading
        })
    }

    onClose = (caller) => {
        caller.setState({showAddIngredientDrawer: false})
    }

    render = () => {

        let caller = this.props.caller;

        /**
         * @type {AppBase}
         */
        let $app = this.context;

        /** @type {InputHelper} */
        let $form = $app.HInput.new(this);
        let $options = caller.state.options

        if (!$options)
            return '';


        let colors = [
            'pink',
            'red',
            'cyan',
            'green',
            'blue',
            'purple',
            'magenta',
        ]
        let $exclude = [];
        let $added = caller.state.items.map((v, k) => {
            $exclude.push(v.id)
            return [(
                <Tag key={v.id} color={colors[4]}>
                    <b style={{fontSize: 1.2 + 'em', padding: '4px 5px', display: 'inline-block'}}>
                        {v.title + ' - [' + caller.props.caller.state.labels['weight'] + ' : ' + v.weight + ']'}
                    </b>
                </Tag>
            )]
        })

        let $selectIngredient = $form.select('ingredientList', $options, {
            onChange: (e) => $form.setAttr('id', e),
            exclude: $exclude
        }, {
            rules: [{required: caller.state.showAddIngredientDrawer, message: ''}]
        });

        let $items = (
            <div>
                <Row>
                    <Col lg={{span: 8}}>
                        {$selectIngredient}
                    </Col>
                    <Col span={8} offset={1}>
                        {$form.number('weight')}
                    </Col>
                </Row>
                <Row>
                    <Col lg={{span: 24}}>
                        {$added}
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col lg={{span: 24}}>
                        {$form.submitButton({
                            loading: this.state.loading,
                            onClick: () => {
                                return false;
                            }
                        }, 'Добавить', {style: {display: 'inline-block','margin-right':'5px'}})}
                        <Button onClick={() => this.onClose(caller)}>
                            Готово
                        </Button>
                    </Col>
                </Row>
            </div>
        )

        let $f = $form.render($items, {
            onFinish: () => {
                const {id, weight} = this.state.attributes
                if (id && weight) {
                    /** @type {AppBase} */
                    let $app = caller.props.caller.context;

                    this.loadingToggle()

                    $app.api.get('/ingredient/' + id, ($res) => {
                        this.loadingToggle()
                        caller.addIngredientItem($res.response.item, weight)
                        $form.setFormValue('ingredientList', null)
                    }, null, false)
                }
            }
        })


        let $config = {
            title: 'Добавить ингредиент',
            placement: 'top',
            closable: true
        }

        return (
            <>
                <Drawer {...$config} onClose={() => this.onClose(caller)}
                        visible={caller.state.showAddIngredientDrawer}>
                    {$f}
                </Drawer>
            </>
        )
    }
}

export default AddIngredientDrawer;