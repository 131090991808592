//
import React from 'react'
import AppContext from "../context/AppContext";

//
import {Spin} from "antd";
import {SettingFilled} from '@ant-design/icons';
//
import NoPage from "./NoPage";
import User from "./pages/User";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import Ingredient from "./pages/Ingredient";
import Recipe from "./pages/Recipe";
import Box from "./pages/Box";
import Manufacturer from "./pages/Manufacturer";
import BoxItem from "./pages/BoxItem";
import IngredientCategory from "./pages/IngredientCategory";
import RecipeCategory from "./pages/RecipeCategory";
import BaseLayoutLoader from "./BaseLayoutLoader";


/**
 *
 * @param $app
 * @constructor
 */
class BaseContent extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            oldContent: '',
            load: null,
        }
    }

    /**
     * @type {function(): AppBase}
     */
    app = () => {
        return this.context
    }

    pageItems = () => {

        const $app = this.app()

        $app.content.setContentStates(this);

        let $pages = []

        $pages.push($app.content.showController(<Dashboard/>, 'Dashboard'))
        $pages.push($app.content.showController(<Profile/>, 'Profile'))
        $pages.push($app.content.showController(<User/>, 'User'))
        $pages.push($app.content.showController(<Ingredient/>, 'Ingredient'))
        $pages.push($app.content.showController(<Recipe/>, 'Recipe'))
        $pages.push($app.content.showController(<Box/>, 'Box'))
        $pages.push($app.content.showController(<BoxItem/>, 'Box-Item'))
        $pages.push($app.content.showController(<Manufacturer/>, 'Manufacturer'))
        $pages.push($app.content.showController(<IngredientCategory/>, 'Ingredient-Category'))
        $pages.push($app.content.showController(<RecipeCategory/>, 'Recipe-Category'))

        let $content = $pages.join('') === '' ? <NoPage/> : $pages
        let $c = ($app.route.action === 'index' && $app.route.controller !== 'profile') || $app.route.action === 'view' ?
            '' : 'site-base-content-container content-block-gray';

        return (
            <div className={$c}>
                {$content}
            </div>
        )
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        let $tmp = document.getElementById("main-content-container").innerHTML
        const strippedString = $tmp.replace(/(<([^>]+)>)/gi, "")
        if (strippedString !== '')
            prevState.oldContent = $tmp
        return prevState
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.load === true) {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 250)
        }
    }

    render = () => {
        const antIcon = <SettingFilled spin style={{fontSize: '1em', color: 'transparent'}}/>;
        return (
            <div>
                <BaseLayoutLoader/>
                <Spin style={{'color': 'transparent'}} spinning={this.state.load} indicator={antIcon}>
                    {this.state.load === true ? <div dangerouslySetInnerHTML={{__html: this.state.oldContent}}/> : ''}
                    <div id={'main-content-container'} style={{display: (this.state.load !== true ? 'block' : 'none')}}>
                        {this.pageItems()}
                    </div>
                </Spin>
            </div>
        )
    }
}

export default BaseContent;