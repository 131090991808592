import React from 'react'
//
import Form from "./Form";

class Create extends React.Component {

    render = () => <Form caller={this}/>

}

export default Create;