//
import React from 'react'
//
import AppContext from "../../../context/AppContext";
import DataTable from "../../template/DataTable";
import AddIngredientDrawer from "./AddIngredientDrawer";
//
import {Button} from "antd";
import {
    DeleteOutlined
} from '@ant-design/icons';

class AddIngredient extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            initialized: false,
            showAddIngredientDrawer: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let $caller = this.props.caller;
        let $formState = $caller.props.caller.state;
        if (($formState && $formState.res.response.related) && !this.state.initialized) {
            let $items = $formState.res.response.related.ingredients.ingredientList;
            this.setState({
                initialized: true,
                items: $items
            })
        }

        let $attributes = $caller.state.attributes
        if ($attributes && $attributes.ingredientList !== this.state.items) {
            $attributes = Object.assign($attributes, {ingredientList: this.state.items})
            $caller.setState({
                attributes: $attributes
            });
        }
    }

    /**
     * format decimals
     * @param $val
     * @return {number}
     */
    formatDec = ($val) => {
        return ($val.toFixed(3) * 1)
    }

    addIngredientItem = ($ingredient, $weight) => {

        let $items = this.state.items;

        let $price = $ingredient.price;
        let $pricePerG = $price / 1000;
        let $cost = $weight * $pricePerG;

        $items.push({
            id: $ingredient.id,
            title: $ingredient.title,
            weight: $weight,
            price: $price,
            cost: this.formatDec($cost),
        })

        this.setState({items: $items})
    }

    unsetIngredient = ($id) => {
        let $items = this.state.items;

        $items.map((v, k) => {
            if (v.id === $id)
                $items.splice(k, 1);
            return k;
        })

        this.setState({items: $items})


    }

    renderIngredientList = () => {
        let $items = this.state.items;
        let $data = [];

        let $labels = this.props.caller.state.labels;
        if (!$labels)
            return '';

        /**
         * @type {AppBase}
         */
            //let $app = this.context;

        let $c = 'руб.'
        let $w = 'гр.'

        let $totalWeight = 0
        let $totalCost = 0
        let $totalPrice = 0

        $items.map((i, k) => {
            let $id = $items[k]['id'];
            let $title = (
                <>
                    {/*<Button type='link' onClick={() => $app.route.to('/ingredient/view/' + $id)}>*/}
                    {$items[k]['title']}
                    {/*</Button>*/}
                </>
            )
            let $weight = this.formatDec(parseFloat($items[k]['weight']))
            let $price = this.formatDec(parseFloat($items[k]['price']))
            let $cost = this.formatDec(parseFloat($items[k]['cost']))

            $totalWeight = this.formatDec($totalWeight + parseFloat($weight))
            $totalPrice = this.formatDec($totalPrice + parseFloat($price))
            $totalCost = this.formatDec($totalCost + $cost)

            let $remove = (
                <Button
                    danger
                    key={$id}
                    block={true}
                    type={'ghost'}
                    title={'Удалить'}
                    icon={<DeleteOutlined/>}
                    className={'remove-recipe-ingredient-bt'}
                    onClick={() => this.unsetIngredient($id)}
                />
            )
            return $data.push([$title, $weight + ' ' + $w, $price + ' ' + $c, $cost + ' ' + $c, $remove])
        })

        if ($data.length > 0) {
            let $f1 = (<h3 style={{textAlign: 'right'}}>Итого</h3>);
            let $f2 = (<b>{$totalWeight} {$w}</b>);
            let $f3 = (<b>{$totalPrice} {$c}</b>);
            let $f4 = (<b>{$totalCost} {$c}</b>);
            $data.push([$f1, $f2, $f3, $f4, <></>])
        }

        let $header = [$labels['title'], $labels['weight'], 'Цена за 1 KG/L', 'Стоимость', 'Действия'];

        return (
            <>
                <h3>Ингредиенты</h3>
                {<DataTable header={$header} data={$data}/>}
            </>
        )
    }

    toggleAddIngredientDrawer = () => {
        let $o = this.props.caller.state.options['ingredientList'];
        let $open = !this.state.showAddIngredientDrawer;
        this.setState({
            showAddIngredientDrawer: $open,
            options: $o
        })
    }

    render = () => {

        return (
            <>
                <Button onClick={() => this.toggleAddIngredientDrawer()}>
                    Добавить ингредиент
                </Button>
                <br/>
                <br/>
                {<AddIngredientDrawer caller={this}/>}
                {this.renderIngredientList()}
            </>
        )
    }
}

export default AddIngredient;