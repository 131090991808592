//
import React from 'react'
import AppContext from "../../../context/AppContext";
//
import {Row, Col, Button} from "antd";
//
import AddNewCategory from "./AddNewCategory";
import AddIngredient from "./AddIngredient";

class Form extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            showCategoryDrawer: false
        }
    }

    /**
     * toggle loading state
     */
    loadToggle = () => this.setState({loading: !this.state.loading})

    /**
     * render content
     * @return {JSX.Element}
     */
    content = () => {

        /** @type {AppBase} */
        const $app = this.context

        /** @type {InputHelper} */
        const $form = this.context.HInput.new(this)

        let $left = (
            <>
                {$form.text('title')}

                {$form.select('entity_type')}

            </>
        );

        let $right = (
            <>
                <Row>
                    <Col span={19}>
                        {$form.select('category_id')}
                    </Col>
                    <Col span={4} offset={1}>
                        <Button className={'btn_select_addon'} block
                                onClick={() => this.setState({showCategoryDrawer: true})}
                                type={'ghost'}>+</Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={10}>
                        {$form.number('entity_amount', {rules: [{required: true}, {min: 1}]})}
                    </Col>
                    <Col span={13} offset={1}>
                        {$form.number('weight', {}, {rules: [{required: true, message: ''}]})}
                    </Col>
                </Row>
            </>
        )

        let $items = $form.renderFieldsByPositions('', $left, $right, <AddIngredient caller={this} form={$form}/>);

        let $formContent = $form.render($items, {
            onFinish: () => {
                this.loadToggle()
                $app.record.save(this.state.attributes, (res) => {
                    this.loadToggle()
                    return !$form.requestHasErrors(res)
                })
            }
        })

        return (
            <>
                {<AddNewCategory caller={this}/>}
                {$formContent}
            </>
        )
    }

    render = () => this.content()
}

export default Form;