//
import React from 'react'
import AppContext from "../../context/AppContext";
//
import Index from "./box/Index";
import View from "./box/View";
import Create from "./box/Create";
import Update from "./box/Update";
import NoPage from "../NoPage";

class Box extends React.Component {

    static contextType = AppContext;

    /**
     * @return {AppBase}
     */
    app = () => {
        return this.context;
    }

    render = () => {
        let $pages = []
        let $app = this.app()

        $pages.push($app.content.showPage(<Index/>, 'Box', 'Index'))
        $pages.push($app.content.showPage(<Create/>, 'Box', 'Create'))
        $pages.push($app.content.showPage(<Update/>, 'Box', 'Update'))
        $pages.push($app.content.showPage(<View/>, 'Box', 'View'))

        return ($pages.join('') === '' ? <NoPage/> : $pages);
    }
}

export default Box;