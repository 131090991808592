import React from 'react'
import AppContext from "../../../context/AppContext";
//
import ViewTable from "../../template/ViewTable";

class View extends React.Component {

    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = null
    }


    componentDidMount() {

        /** @type {AppBase} */
        const $app = this.context

        $app.record.getRecord(($r) => {
            this.setState($r)
        })
    }

    render() {
        return <ViewTable caller={this}  exclude={['tmpPassword']} />
    }

}

export default View;