import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Table as AntTable, ConfigProvider} from 'antd';
import AppContext from "../../context/AppContext";


/**
 * table component
 */
const Table = ($props) => {

    const $app = useContext(AppContext);

    const [options, setOptions] = useState()
    const [state, setState] = useState({
        load: true,
        data: {
            labels: $app.record.getLabels(),
            response: {
                items: false, total: 0, page: {total: 0, size: 0, current: 0},
            }
        }
    });

    const loadData = useCallback(() => {
        if (!state.load)
            return;
        return $app.record.getList(($result) => {
            setState({
                load: false,
                data: $result
            });
            if ($result) {
                if ($result.response && $result.response.options)
                    setOptions($result.response.options)
                let $tmpData = {};
                if ($app.state.table) {
                    let $d = $app.state.table.data;
                    $tmpData[$app.route.controller] = $result;
                    $tmpData = Object.assign($d, $tmpData)
                } else {
                    $tmpData[$app.route.controller] = $result;
                }
                $app.setState({
                    table: {
                        data: $tmpData,
                        name: $app.route.controller,
                        refresh: () => loadData(),
                    }
                })
            }
        });
    }, [$app, state])


    useEffect(() => {
        loadData()
    }, [state, loadData])


    if (state.load === true) {
        return ''
    }

    const changePage = ($page) => {
        $page > 1 ? $app.route.setParam('page', $page) : $app.route.toPage();
        loadData()
    }

    /**
     * @param $response
     * @returns {{columns: [], source: []}}
     */
    const getSourceAndColumns = ($response) => {

        let $actions = false;
        let dataSource = [];
        if (!$response || !$response.items)
            return {};
        Object.entries($response.items).map((k, i) => {
            let $model = k[1];

            let $defaultActionsToCheck = ['view', 'update', 'delete'];
            if ($props.checkActions)
                $defaultActionsToCheck.push($props.checkActions)
            $actions = ($app.content.renderActionButtonsMini({
                    id: $model.id,
                    only: $defaultActionsToCheck
                }
            ));
            if ($props.excludeFields) {
                Object.keys($model).map(($checkKey) => {
                    if ($props.excludeFields.includes($checkKey))
                        delete $model[$checkKey]
                    return '';
                })
            }

            if ($props.optionFields) {
                Object.keys($model).map(($checkKey) => {
                    if ($props.optionFields.includes($checkKey)) {

                        if ($model[$checkKey] !== undefined && options && options[$checkKey]) {
                            let $v = $model[$checkKey];
                            let $optSet = options[$checkKey];
                            if ($optSet[$v])
                                $model[$checkKey] = $optSet[$v]
                        }
                    }
                    return '';
                })
            }
            return dataSource.push(Object.assign($model, {
                key: $model.id,
                actions: $actions
            }));
        });

        let columns = [];
        if (!state.data.labels)
            return {};

        if ($props.excludeFields) {
            Object.keys(state.data.labels).map(($checkKey) => {
                if ($props.excludeFields.includes($checkKey))
                    delete state.data.labels[$checkKey]
                return '';
            })
        }
        Object.entries(state.data.labels).map((k) => {
            return columns.push({title: k[1], dataIndex: k[0], key: k[0]})
        });

        if ($actions) {
            columns.push({title: 'Действия', dataIndex: 'actions', key: 'actions'});
        }

        return {
            source: dataSource,
            columns: columns,
        }
    }


    /**
     * @param $response
     * @returns {{total: number, defaultPageSize: number, size: (number|PaymentItem), onChange: Table.changePage, defaultCurrent: number}}
     */
    const getPageSettings = ($response) => {
        return {
            current: ($response.page.current) + 1,
            defaultCurrent: ($response.page.current) + 1,
            defaultPageSize: $response.page.size,
            size: 1, // max num of pagination buttons
            pageSize: $response.page.size,//items per page
            total: $response.total, /// total item count
            onChange: changePage
        };
    };


    let $response = state.data.response;
    if (!$response)
        return '';

    let $sourceAndColumns = getSourceAndColumns($response);

    let $page = ($response.total <= $response.page.size) ? false :
        getPageSettings($response);


    return (
        <div>
            <ConfigProvider
                // renderEmpty={() => (!state.load && $response.total === 0 ? 'Нет данных' : 'Загрузка ...')}
            >
                <AntTable
                    bordered
                    scroll={{x: 800}}
                    size={$app.state.siteSize}
                    columns={$sourceAndColumns.columns}
                    dataSource={$sourceAndColumns.source}
                    pagination={$page}
                />
            </ConfigProvider>
        </div>
    );
}

export default Table;
