//
import React from 'react'
import AppContext from "../../../context/AppContext";
//
import {Row, Col, Drawer} from "antd";

class AddNewCategory extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    loadingToggle = () => {
        this.setState({
            loading: !this.state.loading
        })
    }

    onClose = (caller) => {
        caller.setState({showCategoryDrawer: false})
    }

    render = () => {

        let caller = this.props.caller;

        /**
         * @type {AppBase}
         */
        let $app = this.context;
        let $form = $app.HInput.new(this);

        let $items = (
            <Row>
                <Col lg={{span: 24}}>
                    {$form.text('title')}
                    {$form.submitButton({
                        loading: this.state.loading
                    })}
                </Col>
            </Row>
        )

        let $f = $form.render($items, {
            onFinish: () => {
                this.loadingToggle()
                $app.record.create(this.state.attributes, (res) => {
                    this.loadingToggle()
                    $form.requestHasErrors(res)
                    if (res.success && res.response.item && !$form.requestHasErrors(res)) {
                        if (res.response && res.response.item) {
                            let $options = caller.state.options
                            let $attributes = caller.state.attributes
                            let $new = {};
                            $new[res.response.item.id] = res.response.item.title
                            $options['category_id'] = Object.assign($options['category_id'], $new)
                            $attributes['category_id'] = res.response.item.id
                            caller.setState({options: $options, attributes: $attributes})

                            if (caller.state.formInstance)
                                caller.state.formInstance.setFieldsValue($attributes)

                            this.onClose(caller)
                            $form.setFormValue('title', null)
                        }
                    }
                }, '/ingredient-category')
            }
        })


        let $config = {
            title: 'Добавить категорию',
            placement: 'top',
            closable: true
        }

        return (
            <>
                <Drawer {...$config} onClose={() => this.onClose(caller)} visible={caller.state.showCategoryDrawer}>
                    {$f}
                </Drawer>
            </>
        )
    }
}

export default AddNewCategory;