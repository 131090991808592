//
import React from 'react'
import AppContext from "../../../context/AppContext";
//
import {Row, Col, Button} from "antd";
import AddNewCategory from "../ingredient/AddNewCategory";
import AddNewManufacturer from "../ingredient/AddNewManufacturer";

//

class Form extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            showCategoryDrawer: false,
            showManufacturerDrawer: false,
        }
    }

    /**
     * toggle loading state
     */
    loadToggle = () => this.setState({loading: !this.state.loading})

    /**
     * render content
     * @return {JSX.Element}
     */
    content = () => {

        /** @type {AppBase} */
        const $app = this.context

        /** @type {InputHelper} */
        const $form = this.context.HInput.new(this)

        let $left = (
            <>
                <Row>
                    <Col span={19}>
                        {$form.text('title')}
                    </Col>
                    <Col span={4} offset={1}>
                        {$form.select('measure')}
                    </Col>
                </Row>
                <Row>
                    <Col span={19}>
                        {$form.select('manufacturer_id')}
                    </Col>
                    <Col span={4} offset={1}>
                        <Button className={'btn_select_addon'} block
                                onClick={() => this.setState({showManufacturerDrawer: true})}
                                type={'ghost'}>+</Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={19}>
                        {$form.select('category_id')}
                    </Col>
                    <Col span={4} offset={1}>
                        <Button className={'btn_select_addon'} block
                                onClick={() => this.setState({showCategoryDrawer: true})}
                                type={'ghost'}>+</Button>
                    </Col>
                </Row>
            </>
        )

        let $right = (
            <>
                {$form.text('account_number')}
                <Row>
                    <Col span={19}>
                        {$form.number('price')}
                    </Col>
                    <Col span={4} offset={1}>
                        {$form.select('currency')}
                    </Col>
                </Row>
                {
                    this.state
                    && this.state.attributes
                    && this.state.attributes.currency === 1
                        ? '' : $form.number('end_price')
                }
            </>
        )

        let $bottom = (
            <>
                {$form.uploader('contract')}
                {$form.textArea('notice')}
            </>
        )

        let $items = $form.renderFieldsByPositions('', $left, $right, $bottom)

        let $formContent = $form.render($items,
            {
                onFinish: () => {
                    this.loadToggle()
                    $app.record.save(this.state.attributes, (res) => {
                        this.loadToggle()
                        return !$form.requestHasErrors(res)
                    })
                }
            }
        )

        return (
            <>
                {<AddNewCategory caller={this}/>}
                {<AddNewManufacturer caller={this}/>}
                {$formContent}
            </>
        )
    }

    render = () => this.content()
}

export default Form;