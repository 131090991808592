//
import React from 'react'
//
import Table from "../../template/Table";


const Index = () => {

    let $conf = {
        excludeFields: ['notice', 'measure', 'status','account_number','contract'],
        optionFields: ['category_id', 'manufacturer_id','currency']
    }

    return <Table {...$conf}/>
}

export default Index;