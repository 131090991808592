//
import React from 'react'
import AppContext from "../../context/AppContext";
//
import Index from "./ingredient-category/Index";
import View from "./ingredient-category/View";
import Create from "./ingredient-category/Create";
import Update from "./ingredient-category/Update";
import NoPage from "../NoPage";

class IngredientCategory extends React.Component {

    static contextType = AppContext;

    /**
     * @return {AppBase}
     */
    app = () => {
        return this.context;
    }

    render = () => {
        let $pages = []
        let $app = this.app()

        $pages.push($app.content.showPage(<Index/>, 'Ingredient-Category', 'Index'))
        $pages.push($app.content.showPage(<Create/>, 'Ingredient-Category', 'Create'))
        $pages.push($app.content.showPage(<Update/>, 'Ingredient-Category', 'Update'))
        $pages.push($app.content.showPage(<View/>, 'Ingredient-Category', 'View'))

        return ($pages.join('') === '' ? <NoPage/> : $pages);
    }
}

export default IngredientCategory;