import React from 'react'
//
import Table from "../../template/Table";

const Index = () => {

    let $conf = {
        optionFields: ['category_id', 'entity_type'],
        excludeFields: ['created_at'],
        checkActions: ['change-permission'],
    }

    return <Table {...$conf}/>

}

export default Index;