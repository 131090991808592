import React from 'react'
//
import Table from "../../template/Table";

const Index = () => {

    let $conf = {
        excludeFields: [],
        optionFields: ['box_id']
    }

    return <Table {...$conf}/>

}

export default Index;