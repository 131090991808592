/**
 * route helper
 */
const RouteHelper = function () {

    this.app = null;
    this.history = null;

    /**
     * set app
     * @param $a
     * @param $h router history context
     * @returns {RouteHelper}
     */
    this.setApp = ($a, $h) => {
        this.app = $a;
        this.history = $h;
        return this.listenHistory();
    }

    /**
     * listen browser history changes
     * @returns {RouteHelper}
     */
    this.listenHistory = () => {
        let $url = window.location.pathname + window.location.search;
        setTimeout(() => {
            if ($url === '/' || $url === '') {
                this.history.replace('/dashboard')
            }
            this.setRouteInfo($url, true)
        }, 1);
        this.setRouteInfo($url, true)
        this.history.listen((location) => {
            let $locationUrl = location.pathname + location.search;
            if ($locationUrl === '/' || $locationUrl === '') {
                $locationUrl = '/dashboard'
                this.history.replace($locationUrl);
            }
            this.setRouteInfo($locationUrl, true)
            this.triggerOnChange();
        })
        return this;
    }

    /**
     * on change events
     * @type {{event: RouteHelper.onChangeEvents.event}}
     */
    this.onChangeEvents = [];

    /**
     * add object with callback to trigger : {test:({RouterHeler})=>{ /*do my code }}
     * @param $callback
     */
    this.onChange = ($callback, $key = null) => {

        if (!$key)
            $key = this.controller + this.action
        let $add = true
        this.onChangeEvents.map(($k) => {
            if ($k[0] === $key)
                $add = false;
            return $k;
        })

        if ($add) {
            let $newEvent = [$key, $callback]
            this.onChangeEvents.push($newEvent)
        }
    }

    /**
     * trigger all events when browser history changes
     */
    this.triggerOnChange = () => {
        this.onChangeEvents.map(($k) => {
            if ($k[0] === this.controller + this.action) {
                return Object.keys($k[1]).map(($k2, $v2) => {
                    if ($k[1][$k2]() !== undefined)
                        $k[1][$k2](this)
                    return ''
                })
            }
            return ''
        })
    }

    this.recordId = null;
    this.action = 'index';
    this.controller = null;
    this.path = window.location.pathname + window.location.search;

    /**
     * @param $url
     * @param $refresh
     * @returns {string|*|RouteHelper}
     */
    this.to = ($url, $refresh = true) => {

        let $oldUrl = window.location.pathname + window.location.search;
        let $pushUrl = !($url === $oldUrl);


        let $a = $url.split('/')[2];
        if ($a === 'delete') {
            this.setRecordId($url);
            return this.app.record.delete(this.recordId);
        }

        ($url === '/') ? this.history.replace('/dashboard') :
            (($pushUrl) ? this.history.push($url) : this.history.replace($url));
        return this.setRouteInfo($url, $refresh)
    }

    /**
     * get record ID from path
     */
    this.setRecordId = ($url) => {
        var $searchForId = window.location.pathname.match(/^\d+|\d+\b|\d+(?=\w)/g);
        if ($url)
            $searchForId = $url.match(/^\d+|\d+\b|\d+(?=\w)/g);

        if (!null && Array.isArray($searchForId)) {
            this.recordId = parseInt($searchForId.map(function (v) {
                return +v;
            })[0]);
        }
    }

    /**
     * set controller, action and record id if exists
     * @param $url
     * @param $refresh
     * @returns {RouteHelper}
     */
    this.setRouteInfo = ($url, $refresh) => {
        if (!$url) {
            $url = window.location.pathname;
        }

        this.setRecordId($url);

        let $c = $url.split('/')[1];
        let $a = $url.split('/')[2];

        let $controller = ($c ? $c : '/').toLocaleLowerCase().split('?')[0];
        let $action = ($a ? $a : 'index').toLocaleLowerCase().split('?')[0];

        this.action = $action;
        this.controller = $controller;

        let $data = {
            action: $action,
            controller: $controller,
        }

        if ($refresh && this.app.state.user) {
            $data = Object.assign($data, {
                refresh: true,
            });
            this.app.content.layout.setState($data)
        }
        this.path = $url;
        return this;

    }

    /**
     * set route param
     * @param paramName
     * @param paramValue
     * @param url
     * @returns {undefined}
     */
    this.setParam = (paramName, paramValue, $callback) => {

        let $url = window.location.pathname + window.location.search;
        let $query = $url.split('?')
        let $replaced = false;
        if ($query[1]) {
            let $paramKeyVal = $query[1].split('&')
            if ($paramKeyVal[1]) {
                $paramKeyVal.map(($k, $v) => {
                    let $param = $k.split('=')
                    if ($param[0] === paramName) {
                        $url = $url.replace(paramName + '=' + $param[1], paramName + '=' + paramValue)
                        $replaced = true;
                    }
                    return $k;
                })
            } else {
                if ($paramKeyVal[0]) {
                    $paramKeyVal.map(($k, $v) => {
                        let $param = $k.split('=')
                        if ($param[0] === paramName) {
                            $url = $url.replace(paramName + '=' + $param[1], paramName + '=' + paramValue)
                            $replaced = true;
                        }
                        return $k;
                    })
                }
            }
        }

        if ($replaced === false) {
            $url = window.location.pathname + (window.location.search !== '' ? window.location.search + '&' : '?') + paramName + '=' + paramValue;
        }
        if (typeof $callback === 'function') {
            this.to($url, false)
            return $callback($url)
        }
        return this.to($url, false)
    }

    /**
     * redirect to page of current controller
     * @param $pageName
     * @param $recId
     * @param $refresh
     * @returns {undefined}
     */
    this.toPage = ($pageName, $recId, $refresh = true) => {
        var $path = '/' + this.controller + ($pageName ? '/' + $pageName + ($recId ? '/' + $recId : '') : '')
        return this.to($path, $refresh)
    }

}

export default (new RouteHelper());
