//
import React from 'react'
//
import AppContext from "../../../context/AppContext";
//
import Form from "./Form";

class Update extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = false
    }

    componentDidMount() {
        /** @type {AppBase} */
        let $app = this.context;
        $app.record.getRecord().then(r => this.setState({res: r}))
    }

    render = () => <Form caller={this}/>

}

export default Update;