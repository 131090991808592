import React from 'react'
import AppContext from "../context/AppContext";
//
import {Layout, Spin, Modal, Row, Col, Breadcrumb} from 'antd';
//
import BaseMenu from './BaseMenu';
import BaseContent from './BaseContent';
import BaseLogin from './BaseLogin';
import {ContentProvider} from "../context/ContentContext";

class BaseLayout extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            title: '',
            width: 0,
            subTitle: ''
        }
    }


    /**
     * @type {function(): AppBase}
     */
    app = () => {
        return this.context
    }


    componentDidMount() {
        let $app = this.app();
        const $providerConf = {
            state: this.state, setState: ($state) => {
                this.setState($state)
            },
            subTitle: this.state.subTitle,
            setSubTitle: ($v) => {
                this.setState({
                    subTitle: $v
                })
            },
            title: this.state.title,
            setTitle: ($v) => {
                this.setState({
                    title: $v
                })
            }
        };
        $app.content.setLayoutStates($providerConf);
        this.setState({
            providerConf: $providerConf
        })
    }


    renderLoginOrContent = (showLogin) => {
        let $app = this.app();
        let $grid = {
            lg: {span: 22, offset: 1},
            md: {span: 22, offset: 1},
            sm: {span: 22, offset: 1},
            xs: {span: 22, offset: 1},
        }
        let $content = (showLogin ? <BaseLogin/> : (
            <Row>
                <Col {...$grid}>
                    <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                        {
                            ($app && $app.content ? $app.content.renderTitle(this.state.title, this.state.subTitle) : '')
                        }
                        <Breadcrumb style={{margin: '16px 0'}}>
                            {/*<Breadcrumb.Item>User</Breadcrumb.Item>*/}
                            {/*<Breadcrumb.Item>Bill</Breadcrumb.Item>*/}
                        </Breadcrumb>
                        <BaseContent app={$app}/>
                    </div>
                </Col>
            </Row>
        ))
        return (($app && $app.state && $app.state.loading) ? (
            <div className="loading-page"><Spin/></div>) : $content);
    }

    applicationModal = () => {
        return (
            <Modal title="-" visible={this.state.modal} onCancel={() => this.setState({modal: false})}>
                {(this.state.modal ? this.state.modal.content : '')}
            </Modal>
        )
    }

    renderContent = () => {
        let $app = this.app();
        return this.renderLoginOrContent(($app && $app.user && $app.user.isGuest))
    }

    render = () => {
        let $app = this.app();
        let $l = (!$app.user.isGuest && !$app.state.loading);
        const {Content} = Layout;
        return (
            <div>
                {this.applicationModal()}
                <ContentProvider value={this.state.providerConf}>
                    <Layout style={{minHeight: '100vh'}}>
                        {$l ?
                            <BaseMenu caller={this}/> : ''}
                        <Layout className="site-layout" style={{marginLeft: ($l ? this.state.width : 0)}}>
                            <Content>
                                {this.renderContent()}
                            </Content>
                        </Layout>
                    </Layout>
                </ContentProvider>
            </div>
        );
    }
}

export default BaseLayout;