import React from 'react'
//
import Table from "../../template/Table";

const Index = () => {

    let $conf = {
        excludeFields: ['status'],
        optionFields: []
    }

    return <Table {...$conf}/>

}

export default Index;