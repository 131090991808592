import React from 'react';
//
import {Table} from 'antd';
//
import AppContext from "../../context/AppContext";

/**
 * DataTable component
 */
class DataTable extends React.Component {

    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {}
    }

    getMainContentData = () => {

        let $h = this.props.header;
        let $d = this.props.data;
        let $f = this.props.footer;
        if (!Array.isArray($d) || $d.length === 0)
            $d = [];

        if ((!Array.isArray($h) || $h.length === 0) && (Array.isArray($d) && $d.length > 0)) {
            $h = []
            $d[0].map((v, i) => {
                return $h.push(i)
            })
        }

        let dataSource = [];
        let $columns = [];

        $h.map((v, k) => {
            return $columns.push({
                title: v,
                dataIndex: v,
            })
        })

        let $i = 0;
        $d.map((x, i) => {
            let $ds = {}
            x.map((v, k) => {
                $ds['key'] = $i;
                $ds[$columns[k]['dataIndex']] = v;
                return $ds;
            })
            $i = $i + 1;
            return dataSource.push($ds);
        })

        /** @type {AppBase} */
        const $app = this.context

        let $defaultTableParams = {
            showHeader: true,
            dataSource: dataSource,
            columns: $columns,
            footer: (typeof $f === 'function') ? $f : false,
            pagination: false,
            size: $app.state.siteSize,
            bordered: true
        }

        return <Table {...$defaultTableParams} />
    }

    render() {
        return this.getMainContentData()
    }

}

export default DataTable;
