//
import React from 'react'
import AppContext from "../../../context/AppContext";

class Form extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            showCategoryDrawer: false
        }
    }

    /**
     * toggle loading state
     */
    loadToggle = () => this.setState({loading: !this.state.loading})

    /**
     * render content
     * @return {JSX.Element}
     */
    content = () => {

        /** @type {AppBase} */
        const $app = this.context

        /** @type {InputHelper} */
        const $form = this.context.HInput.new(this)

        let $left = (
            <>
                {$form.text('title')}
            </>
        )
        let $right = ''

        let $items = $form.renderFieldsByPositions('', $left, $right)

        let $formContent = $form.render($items, {
            onFinish: () => {
                this.loadToggle()
                $app.record.save(this.state.attributes, (res) => {
                    this.loadToggle()
                    return !$form.requestHasErrors(res)
                })
            }
        })

        return (
            <>
                {$formContent}
            </>
        )
    }

    render = () => this.content()
}

export default Form;