/**
 * api helper
 */
const ApiHelper = function () {

    /**
     * @type {App}
     */
    this.app = null;

    /**
     * domain with protocol
     * @type {string}
     */
    this.url = () => {
        let $url = window.location.protocol + '//api.' + window.location.hostname;

        return $url.replace('.react.', '.');
    }

    /**
     * set app
     * @param $a
     * @returns {ApiHelper}
     */
    this.setApp = ($a) => {
        this.app = $a;
        return this;
    }

    this.setLoadingState = ($state) => {
        this.app.setState({loading: $state})
    }

    /**
     * upload file
     * @param $data
     * @param $callback
     * @returns {Promise<void>}
     */
    this.uploadFile = ($data, $callback) => {
        return this.request('POST', '/file/upload', $data, $callback);
    }

    /**
     * make post request
     * @param $path
     * @param $data
     * @param $callback
     * @returns {Promise<void>}
     */
    this.post = ($path, $data, $callback) => {
        return this.request('POST', $path, $data, $callback);
    }


    /**
     * make get request
     * @param $path
     * @param $callback
     * @param $headers
     * @returns {Promise<void>}
     */
    this.get = ($path, $callback, $headers, $loadingState) => {
        return this.request('GET', $path, false, $callback, $headers, $loadingState);
    }

    /**
     *
     * @param $method
     * @param $path
     * @param $data
     * @param $callback
     * @param $requestHeaders
     * @returns {Promise<void>}
     */
    this.request = ($method, $path, $data, $callback, $requestHeaders, $loadingState = true) => {

        let $endpoint = this.url() + $path;
        let $token = this.getToken();
        let $headers = {'Content-Type': 'application/json;charset=UTF-8'};

        if ($token)
            $headers = Object.assign($headers, this.getTokenHeader())

        if ($requestHeaders) {
            $headers = Object.assign($headers, $requestHeaders)
        }

        let $params = {
            method: $method,
            headers: new Headers($headers)
        };


        if ($data && $method !== 'GET' && $method !== 'HEAD') {
            $params = Object.assign($params, {body: JSON.stringify($data)});
        }
        if ($method === 'GET' && $loadingState)
            this.app.content.load(true)
        return fetch($endpoint, $params)
            .then((res) => {
                let $json;
                try {
                    $json = res.json()
                } catch (e) {
                    console.log(e)
                }
                return $json
            })
            .then(
                ($result) => {
                    this.app.content.load(false)
                    if ($result) {
                        if (!$result.success && $result.message) {
                            console.log($result.message)
                        }
                        return (typeof $callback === 'function' ? $callback($result) : console.log($result));
                    } else {
                        console.log('Request gave no result!:')
                        console.log($result)
                    }
                },
                ($error) => {
                    this.app.content.load(false)
                    console.log($error)
                    if ($error)
                        return (typeof $callback === 'function' ? $callback($error) : console.log($error));
                }
            )
    }


    /**
     * set access token
     * @param $token
     * @param $remember
     */
    this.setToken = ($token, $remember) => {
        sessionStorage.setItem('token', $token);
        if ($remember === true) {
            localStorage.setItem('token', $token);
        }
    }

    /**
     * remove token
     */
    this.removeToken = () => {
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
    }


    /**
     * get token
     */
    this.getToken = () => {
        let $t1 = localStorage.getItem('token');
        let $t = $t1 !== null ? $t1 : sessionStorage.getItem('token');
        return $t;
    }

    /**
     * get browser unique id
     * @returns {number}
     */
    this.uniqueId = () => {
        var nav = window.navigator;
        var screen = window.screen;
        var guid = nav.mimeTypes.length;
        guid += nav.userAgent.replace(/\D+/g, '');
        guid += nav.plugins.length;
        guid += screen.height || '';
        guid += screen.width || '';
        guid += screen.pixelDepth || '';
        return guid;
    }

    /**
     * get token
     * @returns {{Authorization: string}}
     */
    this.getTokenHeader = () => {
        return {'Authorization': 'Bearer ' + this.getToken() + '<:::>' + this.uniqueId()}
    }

}

export default (new ApiHelper());
