//
import React from "react";
import {Route} from "react-router-dom";
//
import {Button, Skeleton, Row, Col} from 'antd'
import {
    KeyOutlined,
    EyeOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';

/**
 * content helper
 */
const ContentHelper = function () {

    /**
     * @type {App}
     */
    this.app = null;

    /**
     * @type {App}
     */
    this.content = {};

    /**
     * @type {Basethis.layout}
     */
    this.layout = null;

    /**
     * set app
     * @param $a
     * @returns {ContentHelper}
     */
    this.setApp = ($a) => {
        this.app = $a;
        return this;
    }

    /**
     * set content states
     * @param $c
     * @returns {ContentHelper}
     */
    this.setContentStates = ($c) => {
        this.content = $c;
        return this;
    }

    /**
     * set this.layout states
     * @param $c
     * @returns {ContentHelper}
     */
    this.setLayoutStates = ($c) => {
        this.layout = $c;
        return this;
    }

    /**
     * loading state
     * @param v
     */
    this.load = (v) => {
        if (this.content && typeof this.content.setState === 'function') {
            this.content.setState({
                load: v
            })
        }
    }

    /**
     * check if content is loading
     */
    this.isLoading = () => {
        if (this.content && this.content.state)
            return this.content.state.load
        return true;
    }

    /**
     * page title|subtitle
     * @param $title
     * @param $asSubtitle
     * @returns {*}
     */
    this.setTitle = ($title = '', $asSubtitle = false) => {
        if ($title !== '') {
            if (!$asSubtitle) {
                this.setTitle('')
                this.setTitle('', true)
            }
        }
        if ($title === true && this.app.user.id && this.app.route.controller) {
            let $defaultTitle = ($asSubtitle ? this.app.route.action.toUpperCase() : this.app.route.controller.toUpperCase())
            try {
                let $c = this.app.user.attributes.compartments;
                if ($asSubtitle) {
                    let $actions = this.app.user.attributes.actions[this.app.route.controller];
                    $defaultTitle = this.app.route.action.toUpperCase()
                    Object.keys($actions).map(($k) => {
                        if (this.app.route.action === $k)
                            $defaultTitle = ($actions[$k]['label']).toLowerCase();
                        return $k;
                    })
                } else {
                    Object.keys($c).map(($k) => {
                        if ($k === this.app.route.controller)
                            $defaultTitle = $c[$k]['label'];
                        return $k;
                    })
                }
            } catch (log) {
                console.log(log)
            }
            $title = $defaultTitle
        }

        if ($title !== '') {
            if ($asSubtitle) {
                this.subTitle = $title
            } else {
                document.title = $title;
                this.title = $title
            }
            return $title
        }
        if ($asSubtitle) {
            return this.subTitle
        } else {
            return this.title
        }
    }

    /**
     * get page title
     * @returns {string}
     */
    this.title = ''

    /**
     * get page title
     * @returns {string}
     */
    this.subTitle = ''

    /**
     * get page title
     * @returns {string}
     */
    this.renderTitle = (title, subTitle) => {

        if (!title)
            title = this.setTitle(true)
        if (!subTitle)
            subTitle = this.setTitle(true, true)
        let $ret =
            (
                <div key="page-title-container" className="application-header-title content-block-gray">
                    <Row>
                        <Col lg={12} xs={24}>
                            <h4 key="page-title" className={'site-page-title'}>
                                {
                                    title + (subTitle ? ' - ' + subTitle : '')
                                }
                            </h4>
                        </Col>
                        <Col className={'header-actions-container'} lg={12} xs={24}>
                            {this.getHeaderActions(this.app.user.attributes)}
                        </Col>
                    </Row>
                </div>
            )
        return [$ret]
    }

    /**
     * get actions in header
     * @returns {string}
     */
    this.getHeaderActions = ($user) => {
        if (!$user.id) {
            return '';
        }
        let $actions = {
            index: ['create'],
            create: ['index'],
            view: ['create', 'index', 'update', 'delete'],
            update: ['create', 'index', 'view', 'delete'],
            'change-permission': ['create', 'index', 'view', 'delete'],
        };

        let $availableActions = $user.actions[this.app.route.controller];
        if (!$availableActions)
            return '';

        let $icons = {
            create: <PlusOutlined/>,
            delete: <DeleteOutlined/>,
            update: <EditOutlined/>,
            view: <EyeOutlined/>,
            index: <UnorderedListOutlined/>
        };

        if (!$actions[this.app.route.action])
            return '';

        return Object.keys($actions[this.app.route.action]).map(($k) => {
            let $action = $actions[this.app.route.action][$k];
            if ($availableActions[$action]) {

                let $id = (((['delete', 'view', 'update'].includes($action)) && this.app.route.recordId)
                    ? '/' + this.app.route.recordId : '');

                return (
                    <Button
                        type={'ghost'}
                        key={$k}
                        className={$action + ' header-action-btn'}
                        onClick={() => this.app.route.to($availableActions[$action]['url'] + $id)}
                        title={$availableActions[$action]['label']}
                        icon={$icons[$action]}
                    />
                )
            }
            return '';
        });
    }


    /**
     * show page controller
     * @param $component
     */
    this.showController = ($component, $name) => {
        let $pageName = $name.toLowerCase();
        let $controller = this.app.route.controller;
        if ($pageName === $controller)
            return <Route path={'/' + $controller} key={$controller} strict={false} exact={false}>
                {$component}
            </Route>;
        return '';
    }

    /**
     * show page component
     * @param $component
     * @param $controllerName
     * @param $actionName
     * @returns {JSX.Element|*}
     */
    this.showPage = ($component = false, $controllerName, $actionName) => {


        let $action = this.app.route.action;
        let $controller = this.app.route.controller;


        let $pageName = $actionName.toLowerCase();

        let $currentPath = '/{c}/{a}'
            .split('{c}').join($controller)
            .split('{a}').join($action)


        let $requiredPath = '/{c}/{a}'
            .split('{c}').join($controllerName.toLowerCase())
            .split('{a}').join($pageName)

        let $show = false;

        if ($currentPath === $requiredPath)
            $show = true;


        if ($show === true) {
            // console.log('SHOW')
            // console.log($currentPath)
            // console.log($requiredPath)
            // console.log($p)
            // return <Route key={$pageName} strict={$strict} exact={$exact}
            //               path={$p}>
            //     {$component}
            // </Route>;

            return (
                <div key={window.location.pathname + window.location.search}>
                    {$component}
                </div>
            );
        }

        return '';
    }


    this.renderActionButtonsMini = ($params) => {

        let $l = this.app.record.getActions()

        let $icons = {
            delete: <DeleteOutlined style={{color: 'red'}}/>,
            update: <EditOutlined style={{color: '#ff8300'}}/>,
            view: <EyeOutlined style={{color: 'rgb(44, 72, 225)'}}/>,
            'change-permission': <KeyOutlined/>,
        }

        var $actions = $params.only.map((k) => {
            if ($l[k]) {
                return <Button icon={$icons[k]} size={'small'} type={'text'} key={k + $params.id}
                               title={$l[k]['label']}
                               onClick={() => this.app.route.to($l[k]['url'] + '/' + $params.id)}>
                </Button>
            }
            return '';
        })
        return $actions.join('') === '' ? false : $actions
    }

    this.skelet = ($v) => {
        if ($v)
            return $v
        return (
            <Skeleton.Input style={{width: 100}} rows={1} active={true}/>
        )
    }


    /**
     * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
     *
     * @param {String} text The text to be rendered.
     * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
     *
     * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
     */
    this.getTextWidth = (text, font = '16px Arial') => {
        var canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement("canvas"));
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(text);
        return metrics.width;
    }

}

export default (new ContentHelper());
